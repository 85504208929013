import React, { useEffect, useState } from 'react'

import Text from './Text'
import Icons from './Icons'
import './Intro.scss'

export default function Intro() {
	const [intros, setIntros] = useState([
		{
			title: 'software engineer',
			icons: {
				code: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="code" className="svg-inline--fa fa-code fa-w-20 icon__code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							fill="currentColor"
							d="M416 31.94C416 21.75 408.1 0 384.1 0c-13.98 0-26.87 9.072-30.89 23.18l-128 448c-.8404 2.935-1.241 5.892-1.241 8.801C223.1 490.3 232 512 256 512c13.92 0 26.73-9.157 30.75-23.22l128-448C415.6 37.81 416 34.85 416 31.94zM176 143.1c0-18.28-14.95-32-32-32c-8.188 0-16.38 3.125-22.62 9.376l-112 112C3.125 239.6 0 247.8 0 255.1S3.125 272.4 9.375 278.6l112 112C127.6 396.9 135.8 399.1 144 399.1c17.05 0 32-13.73 32-32c0-8.188-3.125-16.38-9.375-22.63L77.25 255.1l89.38-89.38C172.9 160.3 176 152.2 176 143.1zM640 255.1c0-8.188-3.125-16.38-9.375-22.63l-112-112C512.4 115.1 504.2 111.1 496 111.1c-17.05 0-32 13.73-32 32c0 8.188 3.125 16.38 9.375 22.63l89.38 89.38l-89.38 89.38C467.1 351.6 464 359.8 464 367.1c0 18.28 14.95 32 32 32c8.188 0 16.38-3.125 22.62-9.376l112-112C636.9 272.4 640 264.2 640 255.1z"
						></path>
					</svg>
				),
				git: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="code-merge" className="svg-inline--fa fa-code-merge fa-w-14 icon__git" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path
							fill="currentColor"
							d="M368 192c-32.79 0-60.89 19.77-73.25 48H224c-53.57 0-98.37-37.87-109.3-88.2C141.4 138.9 160 111.7 160 80C160 35.82 124.2 0 80 0C35.82 0 0 35.82 0 80c0 32.79 19.77 60.89 48 73.25v205.5C19.77 371.1 0 399.2 0 432C0 476.2 35.82 512 80 512C124.2 512 160 476.2 160 432c0-32.79-19.77-60.89-48-73.25V263.7C142.5 288.8 181.5 304 224 304h70.75C307.1 332.2 335.2 352 368 352c44.18 0 80-35.82 80-80S412.2 192 368 192zM80 56c13.23 0 24 10.77 24 24S93.23 104 80 104c-13.23 0-24-10.77-24-24S66.77 56 80 56zM80 456c-13.23 0-24-10.77-24-24s10.77-24 24-24c13.23 0 24 10.77 24 24S93.23 456 80 456zM368 296c-13.23 0-24-10.77-24-24s10.77-24 24-24c13.23 0 24 10.77 24 24S381.2 296 368 296z"
						></path>
					</svg>
				),
				binary: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="binary" className="svg-inline--fa fa-binary fa-w-12 icon__binary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
						<path
							fill="currentColor"
							d="M128 448h-16v-128c0-9.703-4.406-18.89-11.97-24.97C92.45 288.1 82.51 286.7 73.07 288.8l-48 10.67c-17.25 3.828-28.12 20.92-24.31 38.17c3.875 17.28 21 28.16 38.19 24.3l9.062-2.016V448h-16c-17.69 0-32 14.33-32 32s14.31 32 32 32h96c17.69 0 32-14.33 32-32S145.7 448 128 448zM256 160c-17.69 0-32 14.33-32 32s14.31 32 32 32h96c17.69 0 32-14.33 32-32s-14.31-32-32-32h-16V32c0-9.703-4.406-18.89-11.97-24.97c-7.594-6.062-17.56-8.344-26.97-6.266l-48 10.67c-17.25 3.828-28.12 20.92-24.31 38.17c3.875 17.28 21.03 28.16 38.19 24.3L272 71.89V160H256zM320 288h-64c-35.28 0-64 28.7-64 64v96c0 35.3 28.72 64 64 64h64c35.28 0 64-28.7 64-64v-96C384 316.7 355.3 288 320 288zM256 448v-96h64l.0313 96H256zM192 64c0-35.3-28.72-64-64-64H64C28.72 0 0 28.7 0 64v96c0 35.3 28.72 64 64 64h64c35.28 0 64-28.7 64-64V64zM64 160V64h64l.0313 96H64z"
						></path>
					</svg>
				),
			},
			color: '#0077ff',
		},
		{
			title: 'tinkerer',
			icons: {
				tools: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="screwdriver-wrench" className="svg-inline--fa fa-screwdriver-wrench fa-w-16 icon__tools" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path
							fill="currentColor"
							d="M331.8 224.1c28.29 0 54.88 10.99 74.86 30.97l19.59 19.59c40.01-17.74 71.25-53.3 81.62-96.65c5.725-23.92 5.34-47.08 .2148-68.4c-2.613-10.88-16.43-14.51-24.34-6.604l-68.9 68.9h-75.6V97.2l68.9-68.9c7.912-7.912 4.275-21.73-6.604-24.34c-21.32-5.125-44.48-5.51-68.4 .2148c-55.3 13.23-98.39 60.22-107.2 116.4C224.5 128.9 224.2 137 224.3 145l82.78 82.86C315.2 225.1 323.5 224.1 331.8 224.1zM384 278.6c-23.16-23.16-57.57-27.57-85.39-13.9L191.1 158L191.1 95.99l-127.1-95.99L0 63.1l96 127.1l62.04 .0077l106.7 106.6c-13.67 27.82-9.251 62.23 13.91 85.39l117 117.1c14.62 14.5 38.21 14.5 52.71-.0016l52.75-52.75c14.5-14.5 14.5-38.08-.0016-52.71L384 278.6zM227.9 307L168.7 247.9l-148.9 148.9c-26.37 26.37-26.37 69.08 0 95.45C32.96 505.4 50.21 512 67.5 512s34.54-6.592 47.72-19.78l119.1-119.1C225.5 352.3 222.6 329.4 227.9 307zM64 472c-13.25 0-24-10.75-24-24c0-13.26 10.75-24 24-24S88 434.7 88 448C88 461.3 77.25 472 64 472z"
						></path>
					</svg>
				),
				flux: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="flux-capacitor" className="svg-inline--fa fa-flux-capacitor fa-w-14 icon__flux" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path
							fill="currentColor"
							d="M448 112v288c-.125 44.13-35.88 79.88-80 80h-288c-44.13-.125-79.88-35.88-80-80v-288C.125 67.88 35.88 32.12 80 32h288C412.1 32.12 447.9 67.88 448 112zM139 238.9l-45-45C86.5 186.1 80.25 171.1 80.25 160.2c0-26.5 21.5-48 48-48c10.88 0 26 6.125 33.75 13.75L206.9 171C207.5 167.4 207.9 163.6 208 160c0-44.12-35.88-80-80-80S48 115.9 48 160S83.88 240 128 240C131.8 239.9 135.4 239.4 139 238.9zM208 262.6V352c0 8.875 7.125 16 16 16s16-7.125 16-16V262.6l91.25-91.38c2.75-3 4.25-6.875 4.5-11c0-4.25-1.75-8.25-4.75-11.25C328 146 324 144.2 319.8 144.2c-4.125 .25-8 1.751-11.13 4.5L224 233.4L139.2 148.8c-3-2.749-6.875-4.25-11-4.5C124 144.2 120 146 117 149C113.1 152 112.2 156 112.2 160.2c.25 4.125 1.75 8 4.375 11L208 262.6zM224 432c44 0 80-35.88 80-79.88c0-22-14.38-50.63-32-63.75V352c0 26.5-21.5 48-48 48S176 378.5 176 352V288.4c-17.62 13.25-32 41.75-32 63.75C144 396.1 180 432 224 432zM400 160c0-44.12-35.88-80-80-80S240 115.9 240 160c.125 3.75 .625 7.375 1.125 11l45-44.1c7.75-7.501 22.75-13.75 33.63-13.75c26.5 0 48 21.5 48 48c0 10.88-6.125 26-13.75 33.75L309 238.9C312.6 239.5 316.4 239.9 320 240C364.1 240 400 204.1 400 160z"
						></path>
					</svg>
				),
				engine: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="engine" className="svg-inline--fa fa-engine fa-w-20 icon__engine" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							fill="currentColor"
							d="M608 192h-32c-17.67 0-32 14.33-32 32v192c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V224C640 206.3 625.7 192 608 192zM434.7 154.5C426.2 147.7 415.7 144 404.8 144H328v-32h64C405.3 112 416 101.3 416 88C416 74.74 405.3 64 392 64h-176C202.7 64 192 74.74 192 88C192 101.3 202.7 112 216 112h64v32H128C101.5 144 80 165.5 80 192v40h-32v-80C48 138.7 37.25 128 24 128S0 138.7 0 152v208C0 373.3 10.75 384 24 384s24-10.75 24-24v-80h32v56C80 362.5 101.5 384 128 384h41.38l49.95 49.95C228.3 442.9 240.5 448 253.3 448H464c26.51 0 48-21.49 48-48V223.1c0-4.855-2.207-9.449-6.001-12.48L434.7 154.5zM192 280C178.7 280 168 269.3 168 256c0-13.26 10.75-24 24-24S216 242.7 216 256C216 269.3 205.3 280 192 280zM288 280C274.7 280 264 269.3 264 256c0-13.26 10.75-24 24-24S312 242.7 312 256C312 269.3 301.3 280 288 280zM384 280c-13.25 0-24-10.75-24-24c0-13.26 10.75-24 24-24S408 242.7 408 256C408 269.3 397.3 280 384 280z"
						></path>
					</svg>
				),
			},
			color: '#4da74d',
		},
		{
			title: 'learner',
			icons: {
				brain: (
					<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="brain" className="svg-inline--fa fa-brain fa-w-16 icon__brain" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path d="M512 288c0-24.14-9.002-46.92-24.84-64.35C487.7 219.8 488 215.9 488 212c0-29.63-15.9-56.33-40.01-71.27C447.1 140.3 448 139.9 448 139.5c0-30.73-16.36-58.07-41.8-72.86C398.2 28.62 364.4 0 324 0C296.1 0 271.3 13.7 256 34.74C240.7 13.7 215.9 0 188 0C147.6 0 113.8 28.62 105.8 66.64c-23.44 13.63-39.17 37.9-41.5 65.66C39.9 147.2 24 174.1 24 204c0 6.295 .7051 12.52 2.086 18.58C9.588 240.1 0 263.5 0 288c0 28.6 12.5 54.88 33.38 72.75C32.47 365.8 32 370.9 32 376c0 48.08 38.76 87.27 86.68 87.98C131 492.2 159.3 512 192 512c26.14 0 49.39-12.6 64-32.05C270.6 499.4 293.9 512 320 512c32.75 0 60.96-19.78 73.32-48.01C441.2 463.3 480 424.1 480 376c0-5.115-.4648-10.23-1.381-15.26C499.5 342.9 512 316.6 512 288zM240 432C240 458.5 218.5 480 192 480s-48-21.49-48-48c0-2.027 .3516-3.959 .5957-5.92C137.1 429.8 128.9 432 120 432C89.07 432 64 406.9 64 376c0-10.54 3.088-20.28 8.148-28.7C48.64 337.8 32 314.9 32 288c0-23.87 13.22-44.45 32.6-55.45C59.19 224.4 56 214.6 56 204c0-25.29 18.06-46.31 41.98-50.99C96.82 148.7 96 144.2 96 139.5c0-24.83 17.29-45.52 40.46-50.98C136.3 87.01 136 85.55 136 84C136 55.28 159.3 32 188 32S240 55.28 240 84V432zM439.9 347.3C444.9 355.7 448 365.5 448 376c0 30.93-25.07 56-56 56c-8.869 0-17.14-2.25-24.6-5.92C367.6 428 368 429.1 368 432c0 26.51-21.49 48-48 48s-48-21.49-48-48V84C272 55.28 295.3 32 324 32s52 23.28 52 52c0 1.549-.3223 3.008-.4551 4.521C398.7 93.98 416 114.7 416 139.5c0 7.58-1.676 14.74-4.566 21.25C436.6 164.4 456 185.8 456 212c0 8.047-1.982 15.57-5.244 22.37C468.3 245.8 480 265.5 480 288C480 314.9 463.4 337.8 439.9 347.3z"></path>
					</svg>
				),
				book: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open-cover" className="svg-inline--fa fa-book-open-cover fa-w-20 icon__book" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							fill="currentColor"
							d="M544 32.01c0-19.62-17.5-34.63-36.89-31.62L336 26.86v384l208-46.23V32.01zM304 26.86L132.9 .3828C113.5-2.617 96 12.38 96 32.01v332.6l208 46.23V26.86zM600.2 32.97L576 39.03v325.6c0 14.1-10.42 27.98-25.06 31.24L320 447.2L89.06 395.9C74.42 392.6 64 379.7 64 364.7V39.03L39.76 32.97C19.56 27.92 0 43.19 0 64.01v351.1c0 15 10.42 27.98 25.06 31.24l288 63.1c4.572 1.016 9.311 1.016 13.88 0l288-63.1C629.6 443.1 640 431 640 416V64.01C640 43.19 620.4 27.92 600.2 32.97z"
						></path>
					</svg>
				),
				telescope: (
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="telescope" className="svg-inline--fa fa-telescope fa-w-20 icon__telescope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							fill="currentColor"
							d="M638.8 216.8l-85.7-206.1C550.8 4.429 544.2 0 538.3 0c-1.75 0-4.511 .5475-6.136 1.219l-117.3 48.61c-5.438 2.266-9.874 8.877-9.874 14.77c0 1.766 .5059 4.502 1.193 6.127l85.73 206.9c2.25 5.453 8.893 9.895 14.8 9.895c1.75 0 4.473-.5345 6.098-1.206l117.3-48.61C635.6 235.5 640 228.9 640 222.1C640 221.2 639.4 218.5 638.8 216.8zM380.4 92.14L74.25 241.7C65.9 245.8 61.99 254.1 65.21 262.8l8.753 21.13L9.878 310.4C4.44 312.7 0 319.3 0 325.2c0 1.75 .5625 4.499 1.219 6.124l20.06 48.42c2.25 5.453 8.876 9.876 14.78 9.876c1.75 0 4.497-.5469 6.122-1.219L106.2 361.9l8.737 21.09c3.219 7.781 12.52 11.49 21.27 8.479l127-43.69c.168 .2168 .3561 .4824 .5261 .6973L217.4 479.1c-4.406 12.5 2.126 26.22 14.63 30.63C234.6 511.6 237.3 512 239.1 512c9.875 0 19.16-6.157 22.63-16l42.9-121.6c4.686 .959 9.507 1.551 14.48 1.551s9.822-.5039 14.51-1.463l42.87 121.5C380.8 505.8 390.1 512 399.1 512c2.656 0 5.344-.4375 8.001-1.375c12.5-4.406 19.03-18.13 14.63-30.63l-46.42-131.5c9.734-12.28 15.79-27.6 15.79-44.49c0-.1309-.0436-.2406-.0436-.3714l66.58-22.87L380.4 92.14zM319.1 327.1c-13.23 0-24-10.77-24-24c0-13.23 10.77-24 24-24c13.23 0 24 10.77 24 24C343.1 317.2 333.2 327.1 319.1 327.1z"
						></path>
					</svg>
				),
			},
			color: '#d36860',
		},
	])

	const titleTimer = 5000
	useEffect(() => {
		setTimeout(() => {
			const newOrder = [...intros.slice(1), intros[0]]

			const outline = document.querySelector('.outline__line')
			console.log(outline)
			outline.classList.add('outline__animation')

			setIntros(newOrder)
		}, titleTimer)
	}, [intros])

	return (
		<div className="intro fullHeight">
			<Icons icons={intros[0].icons} />
			<div className="intro__title">
				<h1>Hello, I'm Bryan.</h1>
				<p className="outline__container">
					And I'm a
					<Text value={intros[0].title} color={intros[0].color} timer={titleTimer} />
				</p>
			</div>
		</div>
	)
}
